<template>  
    <div>
        <h4 class="mb-3">A propos de FormaCloud</h4>

        <p style="text-align: justify;">
            Cette section regroupe les informations concernant FormaCloud et ses différents composants ainsi que la licence associé à celui-ci.
        </p>

        <div v-if="loaded">
        
            <h5 class="mb-3">Licence</h5>
            <div class="alert alert-light mb-3">
                <pre class="mb-0">Limite utilisateurs :          {{usersCount}} sur {{versionsResult.licence.maxUsers}}</pre>
                <pre></pre>
                <pre class="mb-0">Accordée à :                   {{versionsResult.licence.owner}}</pre>
                <pre class="mb-0">                               {{versionsResult.licence.address}}</pre>
            </div>

            <h5 class="mb-3">Services</h5>
            <div class="alert alert-light pb-0">
                <pre class="mb-0">Api :      v{{versionsResult.api}}</pre>
                <pre>Front :    v{{ frontVersion }}</pre>
            </div>

            <h5 class="mb-3">Workers daemon</h5>
            <table class="table mb-3">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Version</th>
                        <th>Forks</th>
                        <th>Dernier appel</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="worker in versionsResult.workers" :key="worker._id">
                        <td><i class="bi" :class="{
                            'bi-arrow-up-circle-fill text-success':  ( (new Date()).getTime() - (new Date(worker.lastCall)).getTime() ) / 1000 < (15*60),
                            'bi-question-circle-fill text-muted':  ( (new Date()).getTime() - (new Date(worker.lastCall)).getTime() ) / 1000 > (15*60),
                        }"></i> {{worker.daemonSerial}}</td>
                        <td>{{worker.daemonVersion}}</td>
                        <td>{{worker.workers}}</td>
                        <td>{{ (new Date(worker.lastCall)).toLocaleDateString() }} à {{ (new Date(worker.lastCall)).toLocaleTimeString() }}</td>
                    </tr>
                </tbody>
            </table>

        </div>
       
    </div>
</template>

<script setup>

    import {onMounted, ref, toRefs, computed, defineEmits, defineProps} from "vue";

    import axios from "axios";

    let props = defineProps(["userInfo"]);

    let loaded = ref(false);
    let versionsResult = ref(null);
    let frontVersion = ref(process.env.VUE_APP_VERSION) || "-";
    let usersCount = ref(0);

    onMounted( () => {
        load();
    })

    function load(){
        loaded.value = false;
        axios.get("/api/configs/versions")
            .then( answer => {
                versionsResult.value = answer.data;
                return axios.get(`/api/users?limit=${versionsResult.value.licence.maxUsers}`)
            })
            .then( answer => {
                usersCount.value = answer.data.length;
                loaded.value = true;
            })
            .catch( answer => {
                alert( answer );
            })
    }


</script>